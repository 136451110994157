// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

const Constants = require('../../../Constants');


/**
 * View Handlers
 */

exports.checkElementVisible = function (element, callback) {
	if (document.getElementById(element) != null) {
		let attempt = 0;
		const timeout = setInterval(() => {
			$(`#${element}:not(.appeared)`)
				.addClass('appeared')
				.each(() => {
					clearTimeout(timeout);
					if (callback) callback(true);
				});
			attempt += 1;
			if (attempt >= 50) {
				clearTimeout(timeout);
				if (callback) callback(true);
			}
		}, 200);
	} else if (callback) callback(true);
};


/**
 * Error Handlers
 */

exports.removeAlert = (elementName, value, shouldChange) => {

	// Remove block alert
	exports.removeBlockAlert();

	// Hide alert
	const element = document.getElementById(elementName);
	element.innerHTML = value;
	if (shouldChange === true) element.className = '';
};

exports.removeBlockAlert = () => {
	if (document.getElementById('block-alert') != null) {
		if (document.getElementById('block-alert').style.height !== '0px') {
			$('#block-alert').animate({ height: '0' }, 300);
		}
	}
};

exports.showBlockAlert = (text, color) => {
	if (text != null) document.getElementById('block-alert-text').innerHTML = text;
	if (color != null) document.getElementById('block-alert-text').style.color = color;
	$('#block-alert').animate({ height: $('#block-alert').get(0).scrollHeight }, 300, function () {
		$(this).height('auto');
	});
};


/**
 * Modal Handlers
 */

exports.showModalWithId = (id) => {
	if (document.getElementById(id).className.indexOf('show-modal') === -1) {
		document.getElementById(id).className = 'modal show-modal';
	}
};

exports.hideModalWithId = (id) => {
	if (document.getElementById(id).className.indexOf('show-modal') > -1) {
		document.getElementById(id).className = 'modal';
	}
};


/**
 * Validators
 */

exports.validateText = (text) => {
	if (text === '' || text.replace(/\s/g, '') === '') {
		return [false,
			'Required'];
	}
	if (/[<>;:/[\]|{}=+]/.test(text)) {
		return [false,
			'Required'];
	}
	return [true,
		null];
};

exports.validateExpDate = (text) => {
	if (text === '' || text.replace(/\s/g, '') === '') {
		return [false,
			'Required'];
	}
	if (!/[\d]{2}\/[\d]{4}/.test(text)) {
		return [false,
			'Required'];
	}
	return [true,
		null];
};

exports.validatePass = (pass) => {
	if (pass === '' || pass.replace(/\s/g, '') === '') {
		return [false,
			'Create a Password (Required)'];
	}
	if (pass.length < 8) {
		return [false,
			'Must be at least 8 characters'];
	}
	if (!Constants.PASSWORD_REGEX.test(pass)) {
		return [false,
			'Must contain a number, uppercase & lowercase letter'];
	}
	return [true,
		null];
};

exports.validateLoginValue = (value) => {
	if (value === '' || value.replace(/\s/g, '') === '') {
		return [false,
			'Required'];
	}
	return [true,
		null];
};

exports.verifyPass = (pass, confirmPass) => {
	if (confirmPass === '' || confirmPass.replace(/\s/g, '') === '') {
		return [false,
			'Required'];
	}
	if (pass !== confirmPass) {
		return [false,
			'Passwords do not match'];
	}

	return [true,
		null];

};

exports.validateEmail = async (email, query) => {
	const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

	// Valid if user's current email
	const currentUser = Parse.User.current();
	if (currentUser != null && currentUser.get('email').toLowerCase() === email.toLowerCase()
		&& window.location.pathname.indexOf('/dashboard/profile') > -1) {
		return [true,
			null];
	}
	if (email === '' || email.replace(/\s/g, '') === '') {
		return [false,
			'Required'];
	}
	if (!emailRegex.test(email)) {
		return [false,
			'Required'];
	}

	// Perform email query
	if (query === true) {
		try {
			const result = await Parse.Cloud.run('checkEmailExists', { email });
			if (result === false) {
				return [true,
					null];
			}
			return [false,
				'Email already exists'];

		} catch (err) {
			return [false,
				'Email already exists'];
		}
	} else {
		return [true,
			null];
	}
};


/**
 * Formatters
 */

exports.formatCountNumber = (number) => (number > 999 ? `${(number / 1000).toFixed(1)}k` : number);

exports.formatNumberWithCommas = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

exports.selectCheckmark = (elementId, checkmarkId) => {
	const element = document.getElementById(elementId);
	const checkmark = document.getElementById(checkmarkId);
	if (checkmark.value === '0') {
		checkmark.value = 1;
		element.className = 'custom-checkbox active';
	} else {
		checkmark.value = 0;
		element.className = 'custom-checkbox';
	}

	// Remove block alert
	exports.removeBlockAlert();
};

exports.selectCheckmarkState = (elementId, checkmarkId, state) => {
	const element = document.getElementById(elementId);
	const checkmark = document.getElementById(checkmarkId);
	if (state === true) {
		checkmark.value = 1;
		element.className = 'custom-checkbox active';
	} else {
		checkmark.value = 0;
		element.className = 'custom-checkbox';
	}

	// Remove block alert
	exports.removeBlockAlert();
};

exports.formatCommasAndSentence = (options) => {
	let sentence = '';
	for (let i = 0; i < options.length; i += 1) {
		if (i > 0) {
			if (i === options.length - 1 && options.length === 2) sentence += ' and ';
			else if (i === options.length - 1) sentence += ', and ';
			else sentence += ', ';
		}
		sentence += options[i];
	}
	return sentence;
};

exports.getParameterByName = (name, url) => {
	let newUrl = url;
	let newName = name;
	if (!newUrl) newUrl = window.location.href;
	newName = newName.replace(/[[\]]/g, '\\$&');
	const regex = new RegExp(`[?&]${newName}(=([^&#]*)|&|#|$)`);
	const results = regex.exec(newUrl);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

exports.toCamelCase = (string) => `${string}`
	.replace(new RegExp(/[-_]+/, 'g'), ' ')
	.replace(new RegExp(/[^\w\s]/, 'g'), '')
	.replace(
		new RegExp(/\s+(.)(\w+)/, 'g'),
		($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
	)
	.replace(new RegExp(/\s/, 'g'), '')
	.replace(new RegExp(/\w/), (s) => s.toLowerCase());

exports.isEquivalent = (a, b) => {

	// Create arrays of property names
	const aProps = Object.getOwnPropertyNames(a);
	const bProps = Object.getOwnPropertyNames(b);

	// If number of properties is different,
	// objects are not equivalent
	if (aProps.length !== bProps.length) {
		return false;
	}

	for (let i = 0; i < aProps.length; i += 1) {
		const propName = aProps[i];

		// If values of same property are not equal,
		// objects are not equivalent
		if (Array.isArray(a[propName])) {
			if (JSON.stringify(a[propName]) !== JSON.stringify(b[propName])) {
				return false;
			}
		} else if (a[propName] !== b[propName]) {
			return false;
		}
	}

	// If we made it this far, objects
	// are considered equivalent
	return true;
};

exports.camelToTitle = (camelCase) => camelCase
	.replace(/([A-Z])/g, (match) => ` ${match}`)
	.replace(/^./, (match) => match.toUpperCase())
	.trim();

exports.isArray = (a) => Array.isArray(a);

exports.isObject = (o) => o === Object(o) && !exports.isArray(o) && typeof o !== 'function';

exports.keysToCamel = (o) => {
	if (exports.isObject(o)) {
		const n = {};
		Object.keys(o)
			.forEach((k) => {
				n[exports.toCamelCase(k)] = exports.keysToCamel(o[k]);
			});
		return n;
	} if (exports.isArray(o)) {
		return o.map((i) => exports.keysToCamel(i));
	}
	return o;
};

exports.getAllUrlParams = (url) => {

	// Get query string from url (optional) or window
	let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

	// We'll store the parameters here
	const obj = {};

	// If query string exists
	if (queryString) {

		// Stuff after # is not part of query string, so get rid of it
		[queryString] = queryString.split('#');

		// Split our query string into its component parts
		const arr = queryString.split('&');
		for (let i = 0; i < arr.length; i += 1) {

			// Separate the keys and the values
			const a = arr[i].split('=');

			// Set parameter name and value (use 'true' if empty)
			const paramName = a[0];
			const paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

			// If the paramName ends with square brackets, e.g. colors[] or colors[2]
			if (paramName.match(/\[(\d+)?\]$/)) {

				// Create key if it doesn't exist
				const key = paramName.replace(/\[(\d+)?\]/, '');
				if (!obj[key]) obj[key] = [];

				// If it's an indexed array e.g. colors[2]
				if (paramName.match(/\[\d+\]$/)) {

					// Get the index value and add the entry at the appropriate position
					const index = /\[(\d+)\]/.exec(paramName)[1];
					obj[key][index] = paramValue;
				} else {

					// Otherwise add the value to the end of the array
					obj[key].push(paramValue);
				}
			} else if (!obj[paramName]) { // We're dealing with a string

				// If it doesn't exist, create property
				obj[paramName] = paramValue;
			} else if (obj[paramName] && typeof obj[paramName] === 'string') {

				// If property does exist and it's a string, convert it to an array
				obj[paramName] = [obj[paramName]];
				obj[paramName].push(paramValue);
			} else {

				// Otherwise add the property
				obj[paramName].push(paramValue);
			}
		}
	}
	return obj;
};


/**
 * Input Handlers
 */

exports.handleIncrementInput = (id, isFloat) => {
	const element = $(`#${id}`);
	let value = 0;
	if (isFloat === true) {
		value = (!Number.isNaN(Number.parseFloat(element.val()))) ? Number.parseFloat(element.val()) : 0.00;
		element.val((value + 1).toFixed(2));
		$(`[data-set-input-id="${id}"]`).each(function () {
			$(this).html((value + 1).toFixed(2));
		});
	} else {
		value = (!Number.isNaN(Number.parseInt(element.val(), 10))) ? Number.parseInt(element.val(), 10) : 0;
		element.val(value + 1);
		$(`[data-set-input-id="${id}"]`).each(function () {
			$(this).html(value + 1);
		});
	}
};

exports.handleDecrementInput = (id, isFloat) => {
	const element = $(`#${id}`);
	let value = 0;
	if (isFloat === true) {
		value = (!Number.isNaN(Number.parseFloat(element.val()))) ? Number.parseFloat(element.val()) : 0.00;
		if ((value - 1).toFixed(2) >= 0) {
			element.val((value - 1).toFixed(2));
			$(`[data-set-input-id="${id}"]`).each(function () {
				$(this).html((value - 1).toFixed(2));
			});
		}
	} else {
		value = (!Number.isNaN(Number.parseInt(element.val(), 10))) ? Number.parseInt(element.val(), 10) : 0;
		if ((value - 1) >= 0) {
			element.val(value - 1);
			$(`[data-set-input-id="${id}"]`).each(function () {
				$(this).html(value - 1);
			});
		}
	}
};
