// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

const Constants = require('../../../Constants');


/**
 * Handlers
 */

exports.getCookie = (name) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
	return undefined;
};

exports.isConsentLevelEnabled = (level) => {
	const cookie = exports.getCookie(level);
	if (cookie != null) return cookie === 'true';
	return false;
};

exports.formatDateTime = (dateObj) => {
	const date = dateObj.getDate();
	const month = Constants.ABR_MONTH_NAMES[dateObj.getMonth()];
	const year = dateObj.getFullYear();
	let hour = dateObj.getHours();
	let minute = dateObj.getMinutes();
	const amPM = (hour > 11) ? ' PM' : ' AM';
	if (hour > 12) {
		hour -= 12;
	} else if (hour === 0) {
		hour = '12';
	}
	if (minute < 10) {
		minute = `0${minute}`;
	}
	return `${date} ${month} ${year} at ${hour}:${minute}${amPM}`;
};

exports.getSignUpType = () => {

	// Get core config type for entity
	let configType = 'academic';
	switch (process.env.ENTITY) {
		case 'smarttrack':
			configType = 'academic';
			break;
		case 'keelerqik':
			configType = 'organization';
			break;
		default:
			break;
	}

	// Get explicit config type
	if (window.location.search.indexOf('type=org') > -1) {
		configType = 'organization';
	} else if (window.location.search.indexOf('type=academic') > -1) {
		configType = 'academic';
	}

	// Return type
	return configType;
};
