// COPYRIGHT SMARTTRACK

/**
 * Entity Constants
 */

exports.ENTITY_CONSTANTS = {
	smarttrack: {

		// General
		MOBILE_URI_SCHEMA: 'smarttrack://',
		IOS_APP_URL: 'https://itunes.apple.com/us/app/smarttrack-intelligent-asset-management/id1032994978?mt=8',
		ANDROID_APP_URL: 'https://play.google.com/store/apps/details?id=com.smarttrack.smarttrack'
	},
	keelerqik: {

		// General
		MOBILE_URI_SCHEMA: 'keelerqik://',
		IOS_APP_URL: 'https://apps.apple.com/us/app/keeler-qik/id1557942615',
		ANDROID_APP_URL: 'https://play.google.com/store/apps/details?id=com.smarttrack.keelerusa',

		// Roles
		SALES_REP_ROLE_DISPLAY: 'Keeler Sales Rep',
		SALES_REP_ROLE_VALUE: 'super-sales',
	}
};


/**
 * General
 */

exports.PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
exports.EMAIL_REGEX = /(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})/g;


/**
 * Cookies
 */

exports.USER_IMPERSONATION_COOKIE = 'user_imp_token';
exports.CONSENT_ACTION_TAKEN_COOKIE = 'c_action_taken';
exports.CONSENT_PERFORMANCE_ENABLED_COOKIE = 'c_performance_enabled';
exports.CONSENT_ANALYTICS_ENABLED_COOKIE = 'c_analytics_enabled';
exports.CONSENT_TARGETING_ENABLED_COOKIE = 'c_targeting_enabled';
exports.CONSENT_COOKIE_EXPIRATION = 365 * 24 * 60 * 60 * 1000; // One year


/**
 * Headers
 */

exports.USER_IMPERSONATION_HEADER = 'x-user-imp-token';


/**
 * Month Names
 */

exports.MONTH_NAMES = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];
exports.ABR_MONTH_NAMES = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec'
];


/**
 * Days of Week
 */

exports.DAYS_OF_WEEK = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday'
];


/**
 * View Contexts
 */

exports.VIEW_LOAD_CONTEXTS = {
	CONTEXT_ONBOARDING: 'CONTEXT_ONBOARDING',
	CONTEXT_DASHBOARD: 'CONTEXT_DASHBOARD',
	CONTEXT_ACTIVITY: 'CONTEXT_ACTIVITY',
	CONTEXT_HISTORY: 'CONTEXT_HISTORY'
};


/**
 * Purchase Flow IDs
 */

exports.PURCHASE_FLOW_IDS = {
	KEELER_QIK_PO: 'keeler_qik_po'
};


/**
 * Available Metrics Types
 */

exports.AVAILABLE_METRIC_TYPES = {
	ORDER_HISTORY: 'order-history', // Order History (orders through the app only)
	LOW_STOCK_LEVEL: 'low-stock-level', // Products most likely to run out / hit low level threshold
	USAGE_TRENDS: 'usage-trends', // Monthly trends summary that shows biggest increase in use or decrease in use
	USAGE_QUANTITY: 'usage', // QTY of each product used per month and the ability to see historical months so they can see trends for each item (this would be viewed by month, i.e. you choose the month and it shows the QTY of each product used from greatest to least)
	INVENTORY_STOCK_LEVEL: 'inventory-stock-level', // Number of SKUs in inventory in each month
	SUBSCRIPTION_PAYMENTS: 'subscriptions-payments' // Subscription payments that have gone through
};
