// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

const recaptchaScript = require('../../includes/components/recaptcha.php')()
	.replace('<?php echo $RECAPTCHA_KEY; ?>', process.env.RECAPTCHA_PUBLIC_KEY)
	.replace('async defer', '');


/**
 * Global Variables
 */

let captchaLoaded = false;


/**
 * Handlers
 */

const deferRecaptcha = (method) => {
	if (typeof grecaptcha !== 'undefined') {
		method();
	} else {
		setTimeout(() => { deferRecaptcha(method); }, 50);
	}
};

const executeRecaptcha = () => {
	if (captchaLoaded !== true) {
		grecaptcha.ready(() => {
			captchaLoaded = true;
			grecaptcha.execute(process.env.RECAPTCHA_PUBLIC_KEY, { action: 'validate_captcha' }).then((token) => {
				$('#g-recaptcha-response').val(token);
			});
		});
	}
};

exports.handlePerformanceConsentUpdate = (granted) => {
	if (granted === true) {

		// Trigger GTM Performance
		try {
			window.dataLayer.push({ event: 'consent_given_performance' });
		} catch (err) {}

		// Set up reCAPTCHA if needed
		if ($('#recaptcha-page-script').length === 0) {
			$(recaptchaScript).appendTo(document.head);

			// Init reCAPTCHA
			if (window.location.pathname === '/contact') {
				deferRecaptcha(executeRecaptcha);
			}
		}
	}
};

exports.handleAnalyticsConsentUpdate = (granted) => {
	if (granted === true) {

		// Trigger GTM Analytics
		try {
			window.dataLayer.push({ event: 'consent_given_analytics' });
		} catch (err) {}
	}
};

exports.handleTargetingConsentUpdate = (granted) => {
	if (granted === true) {

		// Trigger GTM Targeting
		try {
			window.dataLayer.push({ event: 'consent_given_targeting' });
		} catch (err) {}
	}
};
