// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

// Babel
require('core-js/stable');
require('regenerator-runtime/runtime');

// Modules
require('../modules/general');
