// COPYRIGHT SMARTTRACK

/*
 * Requires
 */

// CSS
require('foundation-sites/dist/css/foundation.css');
require('../../css/app.css');
require('../../css/media.css');

// Javascript
const Parse = require('parse');
const jQuery = require('jquery');
require('foundation-sites');
const { handleUpdateUser } = require('./external-user');
const Constants = require('../../../Constants');
const { getAllUrlParams } = require('./input');
const { handleLoginActions } = require('./external-user');
const { getCookie } = require('./utilities');
const {
	handlePerformanceConsentUpdate,
	handleAnalyticsConsentUpdate,
	handleTargetingConsentUpdate
} = require('./cookies');

// Set Globals
window.$ = jQuery;
window.jQuery = jQuery;
window.Parse = Parse;


/**
 * Handlers
 */

const handleImpersonationState = () => {

	// Get token
	const token = getCookie(Constants.USER_IMPERSONATION_COOKIE);
	if (token != null && token !== '') {

		// Set core manager header
		const headerObj = {};
		headerObj[Constants.USER_IMPERSONATION_HEADER] = token;
		Parse.CoreManager.set('REQUEST_HEADERS', headerObj);
	}
};

// Handle functions on document load
$(document).ready(async () => {

	// Handle impersonator token
	if (window.location.search.indexOf('imptoken=') > -1) {

		// Get token
		const { imptoken } = getAllUrlParams();

		// Set state cookie
		const cookieDomain = (process.env.ENV === 'development') ? '' : 'domain=gosmarttrack.com;';
		document.cookie = `${Constants.USER_IMPERSONATION_COOKIE}=${imptoken}; path=/; ${cookieDomain}`;

		// Update state
		handleImpersonationState();

		// Logout user
		try { await Parse.Cloud.run('logoutUser', null); } catch (err) {}
		try { await Parse.User.logOut(); } catch (err) {}

		// Fetch user state
		const { sessionToken } = await Parse.Cloud.run('validateImpersonationIntent');

		// Become user
		await Parse.User.become(sessionToken);

		// Handle user actions
		await handleLoginActions(false);
	}

	// Remove top hash from URL
	const urlString = window.location.toString();
	if (urlString.indexOf('#') !== -1) {
		const newurlString = urlString.split('#')[0];
		if (urlString.split('#')[1] === 'top' || urlString.split('#')[1] === '') {
			window.history.pushState(null, '', newurlString);
		}
	}
});

// Enable Smooth Scroll
const $root = $('html, body');
$('a').click(function () {
	if ($(this).attr('href') === '#' && $(this).attr('id') === 'toggle-menu') {
		if ($('#expanded-header').hasClass('expanded')) {
			$('#mobile-menu-arrow').attr('src', '/img/icons/menu-arrow-colored.png');
		} else {
			$('#mobile-menu-arrow').attr('src', '/img/icons/menu-arrow-open-colored.png');
		}
		$('#expanded-header').toggleClass('expanded');
		return false;
	}
	if ($(this).attr('href') === '#' && $(this).attr('id') === 'toggle-app-menu') {
		$('#dropdown-menu').toggleClass('show');
		$('#menu-arrow').toggleClass('open');
		return false;
	}
	if ($(this).attr('href') === '#' && $(this).attr('id') === 'toggle-legal-menu') {
		$('#legal-dropdown-menu').toggleClass('show');
		$('#legal-menu-arrow-open').toggleClass('hide');
		$('#legal-menu-arrow-close').toggleClass('hide');
		return false;
	}
	if ($(this).attr('href') && $(this).attr('href').match('^/#')) {
		if (window.location.pathname === '/') {
			$root.animate({
				scrollTop: $($(this).attr('href').substring(1)).offset().top
			}, 500);
		} else {
			window.location = $(this).attr('href');
		}
		return false;
	}
	return undefined;
});


/**
 * Cookie Bar Handlers
 */

// Handle cookie bar accept action
$('#cookie-bar-accept-all-action').click(() => {

	// Set all cookies
	const expires = (new Date(Date.now() + Constants.CONSENT_COOKIE_EXPIRATION)).toUTCString();
	document.cookie = `${Constants.CONSENT_ACTION_TAKEN_COOKIE}=true; expires=${expires}; path=/`;
	document.cookie = `${Constants.CONSENT_PERFORMANCE_ENABLED_COOKIE}=true; expires=${expires}; path=/`;
	document.cookie = `${Constants.CONSENT_ANALYTICS_ENABLED_COOKIE}=true; expires=${expires}; path=/`;
	document.cookie = `${Constants.CONSENT_TARGETING_ENABLED_COOKIE}=true; expires=${expires}; path=/`;

	// Hide cookie bar
	const value = `-${$('#cookie-consent-bar').outerHeight()}px`;
	$('#cookie-consent-bar').animate({ bottom: value }, 300, function () {
		$(this).hide();
	});

	// Handle consent updates
	handlePerformanceConsentUpdate(true);
	handleAnalyticsConsentUpdate(true);
	handleTargetingConsentUpdate(true);
});

// Handle cookie bar customize settings action
$('#cookie-bar-customize-action').click(() => {

	// Set action taken cookie
	const expires = (new Date(Date.now() + Constants.CONSENT_COOKIE_EXPIRATION)).toUTCString();
	document.cookie = `${Constants.CONSENT_ACTION_TAKEN_COOKIE}=true; expires=${expires}; path=/`;

	// Move to cookie customize
	window.location = '/cookie-statement#settings';
});


/**
 * Init
 */

// Initialize Foundation
$(document).foundation();

// Activate post-load features
$(document).ready(() => {
	$('body').removeClass('preload');
});

// Initialize Parse
Parse.initialize(process.env.APP_ID);
Parse.serverURL = process.env.SERVER_URL;

// Update user on load
handleUpdateUser();

// Handle impersonation state
handleImpersonationState();
