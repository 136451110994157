// COPYRIGHT SMARTTRACK

/**
 * Requires
 */

const externalUserModule = require('./external-user');


/**
 * Handlers
 */

const updateUserNavigationState = () => {
	if (Parse && Parse.User && Parse.User.current()) {
		const loginItems = document.getElementsByName('footer-login-item');
		const dashboardItems = document.getElementsByName('footer-dashboard-item');
		for (let i = 0; i < loginItems.length; i += 1) loginItems[i].style.display = 'none';
		for (let i = 0; i < dashboardItems.length; i += 1) dashboardItems[i].style.display = 'inline';
	} else {
		const loginItems = document.getElementsByName('footer-login-item');
		const dashboardItems = document.getElementsByName('footer-dashboard-item');
		for (let i = 0; i < loginItems.length; i += 1) loginItems[i].style.display = 'inline';
		for (let i = 0; i < dashboardItems.length; i += 1) dashboardItems[i].style.display = 'none';
	}
};

function handleScrollHeader() {

	// Get parameters
	const scrollPos = $(document).scrollTop();
	const topComponentHeight = $('#header-top-bar-component').outerHeight();

	// Select scrolling headers
	$('#header-section').each(function () {
		if (scrollPos < topComponentHeight) {
			$(this).children('.header').css('position', 'absolute');
			$(this).children('.header').css('top', '');
		} else {
			$(this).children('.header').css('top', '0px');
			$(this).children('.header').css('position', 'fixed');
		}
	});
}

// Handle navigation display
exports.handleNavigationDisplay = (shouldRemoveImage) => {

	// Get current user
	const currentUser = Parse.User.current();

	// Get profile picture elements
	const profileImageIcons = document.getElementsByName('profile-image-icon');
	const profileImageIconImages = document.getElementsByName('profile-image-icon-image');
	const defaultInitials = document.getElementsByName('default-initial');
	const profileFullNames = document.getElementsByName('profile-full-name');
	const profileEmails = document.getElementsByName('profile-email-address');

	// Get display name
	let displayname = process.env.ENTITY_OBJECT.NAME;
	const displayemail = currentUser.get('username');
	if (currentUser.get('email') != null) {
		displayname = currentUser.get('email');
	}
	if (currentUser.get('firstName') != null) {
		displayname = currentUser.get('firstName');
	}
	if (currentUser.get('lastName') != null) {
		if (displayname !== currentUser.get('email')) {
			displayname += (` ${currentUser.get('lastName')}`);
		}
	}

	// Get display initial
	const initial = displayname.charAt(0).toUpperCase();

	// Get profile image
	const profileImage = currentUser.get('profileImageUrl');

	// Set profile picture content
	if (shouldRemoveImage !== true && profileImage != null && profileImage !== '') {
		if (defaultInitials.length > 0) {
			for (let i = 0; i < defaultInitials.length; i += 1) {
				defaultInitials[i].style.display = 'none';
			}
		}
		if (profileImageIcons.length > 0) {
			for (let i = 0; i < profileImageIcons.length; i += 1) {
				profileImageIcons[i].style.backgroundImage = `url('${profileImage}')`;
				profileImageIcons[i].setAttribute('data-url', profileImage);
			}
		}
		if (profileImageIconImages.length > 0) {
			for (let i = 0; i < profileImageIconImages.length; i += 1) {
				profileImageIconImages[i].src = profileImage;
			}
		}
	} else {
		if (defaultInitials.length > 0) {
			for (let i = 0; i < defaultInitials.length; i += 1) {
				defaultInitials[i].innerHTML = initial;
				defaultInitials[i].style.display = 'block';
			}
		}
		if (profileImageIcons.length > 0) {
			for (let i = 0; i < profileImageIcons.length; i += 1) {
				profileImageIcons[i].style.backgroundColor = 'white';
			}
		}
		if (profileImageIcons.length > 0) {
			for (let i = 0; i < profileImageIcons.length; i += 1) {
				profileImageIcons[i].style.backgroundImage = '';
				profileImageIcons[i].removeAttribute('data-url');
			}
		}
	}

	// Set full name content
	if (profileFullNames.length > 0) {
		for (let i = 0; i < profileFullNames.length; i += 1) {
			profileFullNames[i].innerHTML = displayname;
		}
	}

	// Set email content
	if (profileEmails.length > 0) {
		for (let i = 0; i < profileEmails.length; i += 1) {
			profileEmails[i].innerHTML = displayemail;
		}
	}
};

exports.updateNavigationDisplay = (forceNoAuth) => {
	if (Parse && Parse.User && Parse.User.current() && !forceNoAuth && Parse.User.current().get('email') != null) {
		const authNavItems = document.getElementsByName('auth-nav-items');
		const authNavItemsMobile = document.getElementsByName('auth-nav-items-mobile');
		const noAuthNavItems = document.getElementsByName('no-auth-nav-items');
		const authLogoContainer = document.getElementById('auth-logo-container');
		const noAuthLogoContainer = document.getElementById('no-auth-logo-container');
		if (authNavItems.length > 0) {
			for (let i = 0; i < authNavItems.length; i += 1) authNavItems[i].style.display = 'inline';
		}
		if (authNavItemsMobile.length > 0) {
			for (let i = 0; i < authNavItemsMobile.length; i += 1) authNavItemsMobile[i].style.display = 'block';
		}
		if (noAuthNavItems.length > 0) {
			for (let i = 0; i < noAuthNavItems.length; i += 1) noAuthNavItems[i].style.display = 'none';
		}
		if (authLogoContainer != null) {
			authLogoContainer.style.display = 'inline';
		}
		if (noAuthLogoContainer != null) {
			noAuthLogoContainer.style.display = 'none';
		}
		exports.handleNavigationDisplay();
	} else {
		const authNavItems = document.getElementsByName('auth-nav-items');
		const authNavItemsMobile = document.getElementsByName('auth-nav-items-mobile');
		const noAuthNavItems = document.getElementsByName('no-auth-nav-items');
		const authLogoContainer = document.getElementById('auth-logo-container');
		const noAuthLogoContainer = document.getElementById('no-auth-logo-container');
		if (authNavItems.length > 0) {
			for (let i = 0; i < authNavItems.length; i += 1) authNavItems[i].style.display = 'none';
		}
		if (authNavItemsMobile.length > 0) {
			for (let i = 0; i < authNavItemsMobile.length; i += 1) authNavItemsMobile[i].style.display = 'none';
		}
		if (noAuthNavItems.length > 0) {
			for (let i = 0; i < noAuthNavItems.length; i += 1) noAuthNavItems[i].style.display = 'inline';
		}
		if (authLogoContainer != null) {
			authLogoContainer.style.display = 'none';
		}
		if (noAuthLogoContainer != null) {
			noAuthLogoContainer.style.display = 'inline';
		}
	}
};


/**
 * Action Handlers
 */

const createActionHandlers = () => {

	// Check Navigation bar display
	$(window).resize(() => {
		if ($(window).width() >= 906) {
			if ($('#expanded-header').hasClass('expanded')) {
				$('#expanded-header').toggleClass('expanded');
				$('#mobile-menu-arrow').attr('src', '/img/icons/menu-arrow-colored.png');
			}
		}
		if ($(window).width() < 906) {
			if ($('#dropdown-menu').hasClass('show')) {
				$('#dropdown-menu').toggleClass('show');
				$('#menu-arrow').toggleClass('open');
			}
		}
		if (document.getElementById('assets-action-dropdown-large')) {
			if ($(window).width() >= 640) {
				if ($('#assets-action-dropdown-small').hasClass('show')) {
					$('#assets-action-dropdown-small').toggleClass('show');
					$('#assets-action-dropdown-small-arrow').attr('src', '/img/icons/action-arrow.png');
				}
			}
			if ($(window).width() < 640) {
				if ($('#assets-action-dropdown-large').hasClass('show')) {
					$('#assets-action-dropdown-large').toggleClass('show');
					$('#assets-action-dropdown-large-arrow').attr('src', '/img/icons/action-arrow.png');
				}
			}
		}
	});

	// Handle actions
	$("[data-action='logout']").click(() => {
		externalUserModule.handleUserLogOut(true);
	});

	// Handle scroll components
	if ($('#header-section').length) {
		$(document).ready(() => {

			// Handle scroll header
			handleScrollHeader();
		});
		$(document).scroll(() => {

			// Handle scroll header
			handleScrollHeader();
		});
	}

	// Handle cookie bar customize settings action
	$('#end-impersonation-button').click(() => {
		externalUserModule.handleUserLogOut(true);
	});
};


/**
 * Init
 */

exports.initialize = () => {

	// Update navigation display
	exports.updateNavigationDisplay(false);

	// Update user navigation state
	updateUserNavigationState();

	// Create action handlers
	createActionHandlers();
};
