// COPYRIGHT SMARTTRACK

/*
 * Requires
 */

const moment = require('moment-timezone');
const inputModule = require('./input');
const navigationModule = require('./navigation');
const Constants = require('../../../Constants');
const { getCookie } = require('./utilities');


/*
 * Utilities
 */

const removeCollectionData = () => {
	sessionStorage.clear();
};

const createClientState = () => {
	let text = '';
	const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	for (let i = 0; i < 8; i += 1) text += possible.charAt(Math.floor(Math.random() * possible.length));
	return text;
};


/*
 * Onboarding Handlers
 */

exports.handleOnboardingUser = async () => {

	// Get current user
	const currentUser = Parse.User.current();
	if (currentUser != null) { // User is logged in

		// Get state cookies
		const organizationCookie = getCookie('organization');
		const configurationCookie = getCookie('configuration');

		// Check onboarding status
		if (currentUser.get('onboardingComplete') !== true) { // Onboarding not complete
			if (window.location.pathname.indexOf('/enroll/configuration') > -1) {
				if (organizationCookie != null) { // Ensure organization is added
					return true;
				}
				window.location = '/enroll/organization';

			} else if (window.location.pathname.indexOf('/enroll/invite') > -1) {
				if (configurationCookie != null) { // Ensure configuration is added
					return true;
				}
				window.location = '/enroll/configuration';

			} else if (window.location.pathname.indexOf('/enroll/payment') > -1) {
				if (configurationCookie != null) { // Ensure configuration is added
					return true;
				}
				window.location = '/enroll/configuration';

			} else {
				return true;
			}
		} else {
			window.location = '/dashboard/assets';
		}
	} else {
		window.location = '/login';
	}
	return undefined;
};

exports.handleResetOnboarding = async () => {

	// Get current user
	const currentUser = Parse.User.current();
	if (currentUser != null) { // User is logged in

		// Attempt reset onboarding state
		try {
			const didReset = await Parse.Cloud.run('resetOnboardingForUser', null);
			if (didReset === true) {
				exports.handleUserLogOut(false, true);
			} else {
				exports.handleExternalUser(false, true);
			}
		} catch (error) {
			exports.handleUserLogOut(false, true);
		}
	}
};

const handleAccountSetupForUser = async (role) => {
	try {
		const result = await Parse.Cloud.run('handleAccountSetupForUser', { role });
		if (result != null) { // Success
			return true;
		}

		// An error occured
		return false;

	} catch (error) {
		if (error && error.code === 209) {
			exports.handleUserLogOut(true);
		}
		return false;
	}
};


/**
 * Signup Handlers
 */

const createUserForSignup = async (firstName, lastName, email, pass) => {

	// Reset storage
	removeCollectionData();

	// Get timezone
	const timezone = moment.tz.guess();

	// Get role
	let userRole;
	const roleName = window.location.pathname.split('?')[0].replace('/enroll/', '');
	if (roleName === 'standard') userRole = 'standard';
	else if (roleName === 'leader') userRole = 'leader';
	else if (roleName === 'network-leader') userRole = 'super';
	else if (roleName === 'admin') userRole = 'admin';

	// Return user
	return {
		user: {
			firstName,
			lastName,
			email,
			pass,
			timezone
		},
		userRole
	};
};

const handleSignUpActions = async (userRole, appendSearch) => {

	// Save timezone for user
	const currentUser = Parse.User.current();
	const timezone = moment.tz.guess();
	currentUser.set('timezone', timezone);
	currentUser.save();

	// Handle account setup
	const success = await handleAccountSetupForUser(userRole);
	if (success === true) {

		// Set role cookie
		const date = new Date();
		date.setFullYear(date.getFullYear() + 1);
		const cookie = `userRole=${userRole}; expires=${date.toGMTString()}; path=/;`;
		document.cookie = cookie;

		// Set all consent cookies
		const expires = (new Date(Date.now() + Constants.CONSENT_COOKIE_EXPIRATION)).toUTCString();
		document.cookie = `${Constants.CONSENT_ACTION_TAKEN_COOKIE}=true; expires=${expires}; path=/`;
		document.cookie = `${Constants.CONSENT_PERFORMANCE_ENABLED_COOKIE}=true; expires=${expires}; path=/`;
		document.cookie = `${Constants.CONSENT_ANALYTICS_ENABLED_COOKIE}=true; expires=${expires}; path=/`;
		document.cookie = `${Constants.CONSENT_TARGETING_ENABLED_COOKIE}=true; expires=${expires}; path=/`;

		// Move to organization
		if (appendSearch) {
			window.location = `/enroll/organization${window.location.search}`;
		} else {
			window.location = '/enroll/organization';
		}
	} else { // An error occured
		return false;
	}
	return undefined;
};

exports.handleUserSignUp = async () => {

	// Get values
	const firstName = $('#firstName').val();
	const lastName = $('#lastName').val();
	const email = $('#email').val();
	const pass = $('#pass').val();
	const confirmPass = $('#confirmPass').val();

	// Get labels
	const firstNameLabel = $('#firstNameLabel')[0];
	const lastNameLabel = $('#lastNameLabel')[0];
	const emailLabel = $('#emailLabel')[0];
	const passLabel = $('#passLabel')[0];
	const confirmPassLabel = $('#confirmPassLabel')[0];

	// Get validation results
	const firstNameResult = inputModule.validateText(firstName);
	const lastNameResult = inputModule.validateText(lastName);
	const passResult = inputModule.validatePass(pass);
	const confirmPassResult = inputModule.verifyPass(pass, confirmPass);

	// Handle validation
	if (firstNameResult[0] === false) {
		firstNameLabel.innerHTML = `Your First Name (${firstNameResult[1]})`;
		firstNameLabel.className = 'errorLabel';
		firstNameLabel.focus();
	} else if (lastNameResult[0] === false) {
		lastNameLabel.innerHTML = `Your Last Name (${lastNameResult[1]})`;
		lastNameLabel.className = 'errorLabel';
		lastNameLabel.focus();
	} else {
		const emailResult = await inputModule.validateEmail(email, true);
		if (emailResult[0] === false) {
			emailLabel.innerHTML = `Your Email (${emailResult[1]})`;
			emailLabel.className = 'errorLabel';
			emailLabel.focus();
		} else if (passResult[0] === false) {
			[, passLabel.innerHTML] = passResult;
			passLabel.className = 'errorLabel';
			passLabel.focus();
		} else if (confirmPassResult[0] === false) {
			confirmPassLabel.innerHTML = `Confirm Your New Password (${confirmPassResult[1]})`;
			confirmPassLabel.className = 'errorLabel';
			confirmPassLabel.focus();
		} else {

			// Update display
			const buttonTitle = $('#user-action-button-text')[0].innerHTML;
			$('#user-action-button')[0].disabled = true;
			$('#back-button')[0].disabled = true;
			$('#user-action-button-text')[0].innerHTML = '';
			$('#user-action-button-arrow')[0].style.display = 'none';
			$('#button-activity-indicator')[0].style.display = 'block';

			// Create user for signup
			const args = await createUserForSignup(firstName, lastName, email, pass);
			const { userRole, user: userObj } = args;

			// Sign up user
			try {
				const { sessionToken } = await Parse.Cloud.run('registerUser', {
					firstName: userObj.firstName,
					lastName: userObj.lastName,
					email: userObj.email,
					username: userObj.email,
					password: userObj.pass,
					role: userRole,
					timezone: userObj.timezone,
					entity: process.env.ENTITY
				});

				// Become user from session token
				await Parse.User.become(sessionToken);

				// Handle auth actions
				const user = Parse.User.current();

				// Handle successful sign up
				if (user != null && userRole != null) {

					// Handle signup actions
					const success = await handleSignUpActions(userRole, true);
					if (success === false) {

						// Log user out
						exports.handleUserLogOut(false);

						// Reset display elements
						$('#user-action-button')[0].disabled = false;
						$('#back-button')[0].disabled = false;
						$('#user-action-button-text')[0].innerHTML = buttonTitle;
						$('#user-action-button-arrow')[0].style.display = 'inline-block';
						$('#button-activity-indicator')[0].style.display = 'none';

						// Show error
						inputModule.showBlockAlert();
					}
				} else { // An error occured

					// Reset display elements
					$('#user-action-button')[0].disabled = false;
					$('#back-button')[0].disabled = false;
					$('#user-action-button-text')[0].innerHTML = buttonTitle;
					$('#user-action-button-arrow')[0].style.display = 'inline-block';
					$('#button-activity-indicator')[0].style.display = 'none';

					// Show error
					inputModule.showBlockAlert();
				}

			} catch (error) { // An error occured

				// Reset display elements
				$('#user-action-button')[0].disabled = false;
				$('#back-button')[0].disabled = false;
				$('#user-action-button-text')[0].innerHTML = buttonTitle;
				$('#user-action-button-arrow')[0].style.display = 'inline-block';
				$('#button-activity-indicator')[0].style.display = 'none';

				// Show error
				inputModule.showBlockAlert();
			}
		}
	}
};


/**
 * Login Handlers
 */

exports.handleUserLogIn = async () => {

	// Get values
	const email = $('#email').val().toLowerCase();
	const pass = $('#pass').val();

	// Get labels
	const emailLabel = $('#emailLabel')[0];
	const passLabel = $('#passLabel')[0];

	// Get validation results
	const emailResult = inputModule.validateLoginValue(email);
	const passResult = inputModule.validateLoginValue(pass);

	// Handle validation
	if (emailResult[0] === false) {
		emailLabel.innerHTML = `Your Email (${emailResult[1]})`;
		emailLabel.className = 'errorLabel';
		emailLabel.focus();
	} else if (passResult[0] === false) {
		passLabel.innerHTML = `Your Password (${passResult[1]})`;
		passLabel.className = 'errorLabel';
		passLabel.focus();
	} else {

		// Update display
		const buttonText = $('#login-button-text')[0].innerHTML;
		$('#formButton')[0].disabled = true;
		$('#login-button-text')[0].innerHTML = '';
		$('#login-button-arrow')[0].style.display = 'none';
		$('#login-button-indicator')[0].style.display = 'block';

		// Login user
		const success = await exports.loginUserWithParameters(email, pass);
		if (success === false) {

			// Log user out
			exports.handleUserLogOut(false);

			// Reset display elements
			$('#formButton')[0].disabled = false;
			$('#login-button-text')[0].innerHTML = buttonText;
			$('#login-button-arrow')[0].style.display = 'inline-block';
			$('#login-button-indicator')[0].style.display = 'none';
			$('#pass')[0].value = '';

			// Show error
			inputModule.showBlockAlert();
		}
	}
};

exports.handleLoginActions = async (shouldSave) => {

	// Get current user
	const currentUser = Parse.User.current();

	// Save timezone for user
	if (shouldSave === true) {
		const timezone = moment.tz.guess();
		currentUser.set('timezone', timezone);
		currentUser.save();
	}

	// Reset storage
	removeCollectionData();

	// Set all cookies
	const expires = (new Date(Date.now() + Constants.CONSENT_COOKIE_EXPIRATION)).toUTCString();
	document.cookie = `${Constants.CONSENT_ACTION_TAKEN_COOKIE}=true; expires=${expires}; path=/`;
	document.cookie = `${Constants.CONSENT_PERFORMANCE_ENABLED_COOKIE}=true; expires=${expires}; path=/`;
	document.cookie = `${Constants.CONSENT_ANALYTICS_ENABLED_COOKIE}=true; expires=${expires}; path=/`;
	document.cookie = `${Constants.CONSENT_TARGETING_ENABLED_COOKIE}=true; expires=${expires}; path=/`;

	// Handle new user
	const { role, redirect } = await exports.handleExternalUser(false, false, null);
	if (redirect != null) {

		// Handle redirect
		window.location = redirect;

		// Handle return
		return true;
	}
	if (role == null) { // An error occured

		// Handle return
		return false;
	}

	// Redirect to app
	window.location = '/dashboard/assets';

	// Handle return
	return true;
};

exports.loginUserWithParameters = async (email, pass) => {
	try {

		// Log in user
		const { sessionToken } = await Parse.Cloud.run('loginUser', {
			username: email.trim().toLowerCase(),
			password: pass,
			platform: 'web',
			entity: process.env.ENTITY
		});

		// Become user from session token
		await Parse.User.become(sessionToken);

		// Handle login actions
		const success = await exports.handleLoginActions(true);
		return success;

	} catch (error) { // An error occurred
		return false;
	}
};


/**
 * Logout Handlers
 */

exports.handleUserLogOut = async (shouldRedirectToLogin, updateDisplay) => {
	let newUpdateDisplay = updateDisplay;

	// Get update display
	if (newUpdateDisplay == null) newUpdateDisplay = false;

	// If error, update navigation UI
	if (newUpdateDisplay === true) {
		navigationModule.updateNavigationDisplay(true);
	}

	// Remove cookies
	const cookieDomain = (process.env.ENV === 'development') ? '' : 'domain=gosmarttrack.com;';
	document.cookie = 'userRole=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	document.cookie = 'searchString=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	document.cookie = 'showPersonnel=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	document.cookie = 'showMetrics=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	document.cookie = 'showScheduling=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	document.cookie = 'showPurchase=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	document.cookie = 'organization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	document.cookie = 'organizationApproved=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	document.cookie = 'configuration=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	document.cookie = 'subscription=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	document.cookie = 'configRoute=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	document.cookie = `${Constants.USER_IMPERSONATION_COOKIE}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; ${cookieDomain}`;

	// Remove headers
	Parse.CoreManager.set('REQUEST_HEADERS', null);

	// Reset storage
	removeCollectionData();

	// Logout user
	try { await Parse.Cloud.run('logoutUser', null); } catch (err) {}
	try { await Parse.User.logOut(); } catch (err) {}

	// Redirect user
	if (shouldRedirectToLogin === true) window.location = '/login';
};


/**
 * Forgot Password Handlers
 */

exports.handleSendPasswordLink = async () => {

	// Get references
	const email = $('#email').val();
	const emailLabel = $('#emailLabel')[0];

	// Get validation results
	const emailResult = inputModule.validateText(email);

	// Handle validation
	if (emailResult[0] === false) {
		emailLabel.innerHTML = `Your Email (${emailResult[1]})`;
		emailLabel.className = 'errorLabel';
		emailLabel.focus();
	} else {

		// Update display
		$('#formButton')[0].disabled = true;

		// Begin password reset
		try {
			await Parse.Cloud.run('beginPasswordResetWithEmail', { email: email.trim().toLowerCase() });

			// Show alert
			inputModule.showBlockAlert('Success! Check your inbox for an email to reset your account password.');

			// Set delay to login
			setTimeout(() => { window.location = '/login'; }, 2000);

		} catch (error) { // An error occurred

			// Get error content
			const content = error.message;

			// Reset display
			$('#formButton')[0].disabled = false;
			$('#email')[0].value = '';

			// Show alert
			if (content.code === 404) {
				inputModule.showBlockAlert("We can't find an account with that email. Please enter the email associated with your account.");
			} else {
				inputModule.showBlockAlert("We're having some trouble resetting your account password. Please try again.");
			}
		}
	}
};


/*
 * Classlink Handlers
 */

const handleClassLinkAuthStart = (method) => {

	// Create client state
	const clientState = createClientState();

	// Set search string cookie
	const date = new Date();
	date.setHours(date.getHours() + 1);
	const cookie = `classlink_client_state=${clientState}; expires=${date.toGMTString()}; path=/;`;
	document.cookie = cookie;

	// Assemble request
	const redirectUri = `${encodeURIComponent(process.env.CLASSLINK_CALLBACK_URL)}?method=${method}`;
	const clientId = process.env.CLASSLINK_ID;
	const requestUrl = `${process.env.CLASSLINK_REQUEST_URL}?scope=profile&redirect_uri=${redirectUri}&client_id=${clientId}&response_type=code&state=${clientState}`;

	// Request auth route
	window.location = requestUrl;
};

exports.handleClassLinkAuth = async () => {

	// Get method parameters
	const method = inputModule.getParameterByName('method').split('-')[0];
	const platform = inputModule.getParameterByName('method').split('-')[1];
	let role = 'standard';
	if (inputModule.getParameterByName('method').split('-').length > 2) {
		[, , role] = inputModule.getParameterByName('method').split('-');
	}

	// Get invitation method parameters
	let aID = null;
	let bID = null;
	if (method === 'invitation') {
		if (inputModule.getParameterByName('method').split('-').length > 3) {
			[, , aID,
				bID] = inputModule.getParameterByName('method').split('-');
		}
	}

	// Validate method
	if (method === 'signin' || (method === 'invitation' && aID != null && bID != null)
		|| (method === 'signup' && (role === 'standard' || role === 'leader' || role === 'super' || role === 'admin'))) {

		// Get callback parameters
		const error = inputModule.getParameterByName('error');
		const code = inputModule.getParameterByName('code');

		// Redirect for platform
		if (platform === 'ios') {

			// Open app with result
			window.location = `${Constants.ENTITY_CONSTANTS[process.env.ENTITY].MOBILE_URI_SCHEMA}${window.location.search}`;

		} else if (platform === 'android') {

			// Open app with result
			const stateUrl = `${window.location.search}&complete=true`;
			const title = 'Android Authentication';
			const stateObj = { title, route: stateUrl };
			window.history.replaceState(stateObj, title, stateUrl);

		} else if (error == null && (code != null && code !== '')) {

			// Destroy state
			document.cookie = 'classlink_client_state=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

			// Get auth data for user
			await Parse.Cloud.run('handleClasslinkAuth', { classlinkCode: code }).then(async (authData) => {

				// Handle invitation
				if (method === 'invitation') {

					// Create parameters
					const parameters = { aID, bID, authData };

					// Attach user for authdata
					try {

						// Attach authdata to user
						await Parse.Cloud.run('attachInviteAuthdataForUser', parameters);

						// Authenticate user
						const { sessionToken } = await Parse.Cloud.run('loginUserWith', {
							method: 'classlink',
							authData: authData.authData,
							platform: 'web',
							entity: process.env.ENTITY
						});

						// Become user from session token
						await Parse.User.become(sessionToken);

						// Fetch current user
						await Parse.User.current().fetch();

						// Accept invite for user
						const submission = {
							aID,
							bID,
							pass: '',
							firstName: '',
							lastName: ''
						};
						await Parse.Cloud.run('acceptInviteForUser', submission);

					} catch (err) { }
				}

				// Reset storage
				removeCollectionData();

				// Authenticate user
				const { sessionToken } = await Parse.Cloud.run('loginUserWith', {
					method: 'classlink',
					authData: authData.authData,
					platform: 'web',
					entity: process.env.ENTITY
				});

				// Become user from session token
				return Parse.User.become(sessionToken);

			}).then(async (user) => {

				// Check if new user
				const isNew = (user.get('activated') === false && user.get('onboardingComplete') === false);
				if (isNew === true) {

					// Check if signin action with new user
					if (method === 'signin') {

						// Handle reset onboarding
						await exports.handleResetOnboarding();

						// Redirect to signup flow
						window.location = '/enroll?entry=classlink';

					} else {

						// Fetch ClassLink data for user
						try {

							// Fetch current user
							await Parse.User.current().fetch();

							// Handle signup actions
							const success = await handleSignUpActions(role, false);
							if (success === false) {

								// Handle reset onboarding
								await exports.handleResetOnboarding();

								// Redirect with error
								if (role === 'standard') window.location = '/enroll/standard?result=classlink_error';
								else if (role === 'leader') window.location = '/enroll/leader?result=classlink_error';
								else if (role === 'super') window.location = '/enroll/network-leader?result=classlink_error';
								else if (role === 'admin') window.location = '/enroll/admin?result=classlink_error';
							}
						} catch (err) {

							// Handle reset onboarding
							await exports.handleResetOnboarding();

							// Redirect with error
							if (role === 'standard') window.location = '/enroll/standard?result=classlink_error';
							else if (role === 'leader') window.location = '/enroll/leader?result=classlink_error';
							else if (role === 'super') window.location = '/enroll/network-leader?result=classlink_error';
							else if (role === 'admin') window.location = '/enroll/admin?result=classlink_error';
						}
					}
				} else { // Handle login actions
					const success = await exports.handleLoginActions(true);
					if (success === false) { // An error occurred

						// Redirect to login with error
						window.location = '/login?result=classlink_error';
					}
				}
			}).catch(() => {

				// Handle redirection
				if (method === 'signin') window.location = '/login?result=classlink_error';
				else if (method === 'signup') {
					if (role === 'standard') window.location = '/enroll/standard?result=classlink_error';
					else if (role === 'leader') window.location = '/enroll/leader?result=classlink_error';
					else if (role === 'super') window.location = '/enroll/network-leader?result=classlink_error';
					else if (role === 'admin') window.location = '/enroll/admin?result=classlink_error';
				}
			});
		} else {

			// Destroy state
			document.cookie = 'classlink_client_state=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

			// Handle redirection
			if (method === 'signin') window.location = '/login?result=classlink_error';
			else if (method === 'signup') {
				if (role === 'standard') window.location = '/enroll/standard?result=classlink_error';
				else if (role === 'leader') window.location = '/enroll/leader?result=classlink_error';
				else if (role === 'super') window.location = '/enroll/network-leader?result=classlink_error';
				else if (role === 'admin') window.location = '/enroll/admin?result=classlink_error';
			}
		}
	} else {
		window.location = '/login';
	}
};

exports.handleClassLinkSignIn = () => {

	// Update display
	document.getElementById('classlink-button').disabled = true;
	document.getElementById('classlink-button-text').innerHTML = '';
	document.getElementById('classlink-button-icon').style.display = 'none';
	document.getElementById('classlink-button-indicator').style.display = 'block';

	// Handle ClassLink Auth
	handleClassLinkAuthStart('signin-web');
};

exports.handleClassLinkSignUp = (role) => {

	// Update display
	if (document.getElementById('classlink-button') != null) {
		document.getElementById('classlink-button').disabled = true;
		document.getElementById('classlink-button-text').innerHTML = '';
		document.getElementById('classlink-button-icon').style.display = 'none';
		document.getElementById('classlink-button-indicator').style.display = 'block';
	}

	// Get role
	let userRole = role;
	if (userRole == null) {
		const roleName = window.location.pathname.split('?')[0].replace('/enroll/', '');
		if (roleName === 'standard') userRole = 'standard';
		else if (roleName === 'leader') userRole = 'leader';
		else if (roleName === 'network-leader') userRole = 'super';
		else if (roleName === 'admin') userRole = 'admin';
	}

	// Handle ClassLink Auth
	handleClassLinkAuthStart(`signup-web-${userRole}`);
};

exports.handleClassLinkAcceptInvite = (aID, bID) => {

	// Update display
	document.getElementById('classlink-button').disabled = true;
	document.getElementById('classlink-button-text').innerHTML = '';
	document.getElementById('classlink-button-icon').style.display = 'none';
	document.getElementById('classlink-button-indicator').style.display = 'block';

	// Handle ClassLink Auth
	handleClassLinkAuthStart(`invitation-web-${aID}-${bID}`);
};

exports.handleSSOPassthrough = () => {

	// Check for passthrough parameters
	const entry = inputModule.getParameterByName('entry');
	if (entry === 'classlink') {

		// Handle ClassLink signup
		exports.handleClassLinkSignUp();
	} else {
		$(document).ready(() => {
			document.getElementById('signup-body').style.display = 'block';
		});
	}
};


/**
 * User Handlers
 */

const fetchAccountRole = async () => {
	try {
		const result = await Parse.Cloud.run('fetchAccountRole', null);
		if (result != null && result.role != null) { // Valid role

			// Return role
			return result.role;
		}

		// Invalid role
		return null;

	} catch (error) {
		if (error && error.code === 209) {
			exports.handleUserLogOut(true);
		}
		return null;
	}
};

exports.fetchConfigurationForUser = async (attachDefault = true) => {
	try {
		const result = await Parse.Cloud.run('getConfigurationForUser', { attachDefault });
		return {
			configuration: result[0],
			organization: result[1],
			parent: result[2],
			parentParent: result[3]
		};
	} catch (error) {
		if (error && error.code === 209) {
			exports.handleUserLogOut(true);
		}
		return {};
	}
};

exports.fetchSubscriptionForUser = async (fetchCustomer) => {
	try {
		const result = await Parse.Cloud.run('fetchActiveSubscriptionForUser', { fetchCustomer });
		return result;
	} catch (error) {
		if (error && error.code === 209) {
			exports.handleUserLogOut(true);
		}
		return null;
	}
};

exports.fetchSchedulersForUser = async () => {
	try {
		const result = await Parse.Cloud.run('fetchActiveSchedulersForUser', {});
		return result;
	} catch (error) {
		if (error && error.code === 209) {
			exports.handleUserLogOut(true);
		}
		return null;
	}
};

const fetchCurrentUser = async () => {
	try {
		const currentUser = Parse.User.current();
		const fetchedUser = await currentUser.fetch();
		return fetchedUser;
	} catch (err) { // An error occurred
		exports.handleUserLogOut(true);
	}
	return undefined;
};

exports.handleUpdateUser = async () => {

	// Check if valid user
	const user = Parse.User.current();
	if (user == null) return;

	// Initialize should save
	let shouldUpdate = false;

	// If current timezone different than saved, update
	const timezone = moment.tz.guess();
	if (user.get('timezone') !== timezone) {
		user.set('timezone', timezone);
		shouldUpdate = true;
	}

	// Update user
	if (shouldUpdate === true) {
		try { await user.save(); } catch (error) { // Handle session errors
			if (error.code === 209) {

				// Logout user
				exports.handleUserLogOut(false, true);
			}
		}
	}
};

exports.handleExternalUser = async (shouldRedirectToLogin, shouldRedirectToApp, loginURL) => {

	// Get current user
	const currentUser = Parse.User.current();

	// Check auth status
	if (currentUser != null) { // User is logged in

		// Redirect to endpoint if desired
		if (shouldRedirectToApp === true) { // To user endpoint
			window.location = '/dashboard/assets';
		}

		// Get state cookies
		const organizationCookie = getCookie('organization');
		const configurationCookie = getCookie('configuration');
		const subscriptionCookie = getCookie('subscription');
		let userRoleCookie = getCookie('userRole');

		// Check onboarding status
		if (currentUser.get('onboardingComplete') === false) { // Onboarding not complete

			// Check onboarding parameters
			if (userRoleCookie == null) { // User role
				await exports.fetchStateParametersForUser(shouldRedirectToLogin);
				userRoleCookie = getCookie('userRole');
				return { role: userRoleCookie };
			}
			if (organizationCookie == null) { // Organization
				if (window.location.pathname.indexOf('/enroll/organization') === -1) {
					window.location = `/enroll/organization${window.location.search}`;
				} else {
					await exports.fetchStateParametersForUser(shouldRedirectToLogin);
					userRoleCookie = getCookie('userRole');
					return { role: userRoleCookie };
				}
			} else if (configurationCookie == null) { // Configuration
				if (window.location.pathname.indexOf('/enroll/configuration') === -1) {
					window.location = `/enroll/configuration${window.location.search}`;
				} else {
					await exports.fetchStateParametersForUser(shouldRedirectToLogin);
					userRoleCookie = getCookie('userRole');
					return { role: userRoleCookie };
				}
			} else if (window.location.pathname.indexOf('/enroll/invite') > -1) { // Invites
				userRoleCookie = getCookie('userRole');
				return { role: userRoleCookie };
			} else if (subscriptionCookie == null) { // Payment
				if (window.location.pathname.indexOf('/enroll/payment') === -1) {
					window.location = `/enroll/payment${window.location.search}`;
				} else {
					await exports.fetchStateParametersForUser(shouldRedirectToLogin);
					userRoleCookie = getCookie('userRole');
					return { role: userRoleCookie };
				}
			} else {
				await exports.fetchStateParametersForUser(shouldRedirectToLogin);
				userRoleCookie = getCookie('userRole');
				return { role: userRoleCookie };
			}
		} else {

			// Fetch state parameters
			const subscription = await exports.fetchStateParametersForUser(shouldRedirectToLogin);

			// Get cookies
			const organizationApprovedCookie = getCookie('organizationApproved');

			// Check current route
			if (window.location.pathname.indexOf('/enroll') > -1 && window.location.pathname.indexOf('/enroll/confirmation') === -1
				&& subscriptionCookie != null) { // Onboarding complete - Check url for onboarding
				if (organizationApprovedCookie === 'true') {
					window.location = '/dashboard/assets';
				} else {
					window.location = '/waiting-for-approval';
				}
			} else { // Fetch state parameters for user
				try {
					if (subscription != null) {
						if (organizationApprovedCookie === 'true') {
							if (subscription.isActive === true) {

								// Return role
								return { role: getCookie('userRole') };
							}

							// Redirect to inactive subscription screen
							return { redirect: '/subscription-inactive' };
						}

						// Redirect to waiting for approval screen
						return { redirect: '/waiting-for-approval' };

					} if (organizationApprovedCookie === 'true') {
						return { role: null };
					}

					// Redirect to waiting for approval screen
					return { redirect: '/waiting-for-approval' };

				} catch (error) {
					if (organizationApprovedCookie === 'true') {
						return { role: null };
					}

					// Redirect to waiting for approval screen
					return { redirect: '/waiting-for-approval' };
				}
			}
		}
	} else if (shouldRedirectToLogin === true) { // Redirect to login
		if (loginURL != null) window.location = loginURL;
		else window.location = '/login';
	} else { // Return error
		return { role: null };
	}
	return { role: undefined };
};

exports.fetchStateParametersForUser = async (shouldRedirectToLogin) => {

	// Fetch current user
	await fetchCurrentUser();

	// Get user role
	const userRole = await fetchAccountRole();
	if (userRole != null) {

		// Set role cookie
		const date = new Date();
		date.setFullYear(date.getFullYear() + 1);
		const cookie = `userRole=${userRole}; expires=${date.toGMTString()}; path=/;`;
		document.cookie = cookie;

		// Fetch configuration and organization
		const { configuration, organization } = await exports.fetchConfigurationForUser();

		// Validate parameters
		if (configuration != null) {
			date.setFullYear(date.getFullYear() + 1);
			const showPersonnel = (configuration.get('features').show_personnel === true) ? 'true' : 'false';
			const showMetrics = (configuration.get('features').show_metrics === true) ? 'true' : 'false';
			const showScheduling = (configuration.get('features').show_scheduling === true) ? 'true' : 'false';
			const showPurchase = (configuration.get('features').show_purchase_option === true) ? 'true' : 'false';
			document.cookie = `configuration=true; expires=${date.toGMTString()}; path=/;`;
			document.cookie = `showPersonnel=${showPersonnel}; expires=${date.toGMTString()}; path=/;`;
			document.cookie = `showMetrics=${showMetrics}; expires=${date.toGMTString()}; path=/;`;
			document.cookie = `showScheduling=${showScheduling}; expires=${date.toGMTString()}; path=/;`;
			document.cookie = `showPurchase=${showPurchase}; expires=${date.toGMTString()}; path=/;`;
		} else {
			document.cookie = 'configuration=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
			document.cookie = 'showPersonnel=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
			document.cookie = 'showMetrics=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
			document.cookie = 'showScheduling=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
			document.cookie = 'showPurchase=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
		}

		// Set default organization cookies
		if (organization != null) {

			// Set default organization cookie
			date.setFullYear(date.getFullYear() + 1);
			const cookieObj = `organization=true; expires=${date.toGMTString()}; path=/;`;
			document.cookie = cookieObj;

			// Set approval cookie
			const approved = organization.get('isApproved') !== false;
			const approvalCookieObj = `organizationApproved=${approved}; expires=${date.toGMTString()}; path=/;`;
			document.cookie = approvalCookieObj;

		} else {
			document.cookie = 'organization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
		}

		// Fetch subscription for user
		const result = await exports.fetchSubscriptionForUser(false);

		// Validate subscription
		if (result.isActive === true) {
			date.setFullYear(date.getFullYear() + 1);
			const cookieObj = `subscription=true; expires=${date.toGMTString()}; path=/;`;
			document.cookie = cookieObj;
		} else {
			document.cookie = 'subscription=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
		}

		// Return successfully
		return result;
	}

	// Error checking role
	if (shouldRedirectToLogin === true) { // Redirect to login
		exports.handleUserLogOut(true);
	} else {
		exports.handleUserLogOut(false);
		return null;
	}
	return undefined;
};
